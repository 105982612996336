import React from 'react'

export const useTimer = (time: number) => {
  const [counter, setCounter] = React.useState(time)

  React.useEffect(() => {
    const timer = counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
    return () => clearTimeout(timer)
  }, [counter])

  return counter
}
