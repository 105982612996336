import { Icon } from '@iconify/react'
import { Box, Text } from 'grommet'
import React, { useState } from 'react'

import { Input } from '../../../ui'

interface NewAnswerProps {
  index: number
  answer: string
  updateAnswer: (index: number, value: string) => void
  onRemoveAnswer: (index: number) => void
  onToggleEditMode?: (lastMode: boolean) => void
}

const NewAnswer = ({
  index,
  answer,
  updateAnswer,
  onRemoveAnswer,
  onToggleEditMode,
}: NewAnswerProps) => {
  const [isEditMode, setIsEditMode] = useState(false)

  return (
    <Box direction="row" margin={{ vertical: 'medium' }} justify="between">
      <Box flex={{ grow: 0.75 }} margin={{ right: 'large' }} justify="center">
        {isEditMode && (
          <Input
            label={`Answer ${index + 1}`}
            value={answer}
            onChange={e => updateAnswer(index, e.target.value)}
          />
        )}
        {!isEditMode && (
          <Text size="xsmall" truncate>
            Answer {index + 1}: {answer}
          </Text>
        )}
      </Box>
      <Box flex={{ grow: 0.25 }} direction="row" align="center" justify="end">
        <Box
          margin={{ left: 'small' }}
          background={{ color: isEditMode ? 'accent-2' : 'accent-3' }}
          pad="xsmall"
          round="full"
          hoverIndicator={{ background: 'accent-2' }}
          onClick={() => {
            onToggleEditMode?.(!isEditMode)
            setIsEditMode(!isEditMode)
          }}
        >
          <Icon icon="mdi:edit" fontSize={22} />
        </Box>
        <Box
          margin={{ left: 'small' }}
          background={{ color: 'accent-1' }}
          pad="xsmall"
          round="full"
          hoverIndicator={{ background: 'accent-2' }}
          onClick={() => {
            if (isEditMode) setIsEditMode(false)
            onRemoveAnswer(index)
          }}
        >
          <Icon icon="mdi:delete" fontSize={22} />
        </Box>
      </Box>
    </Box>
  )
}

export default NewAnswer
