// src: https://www.npmjs.com/package/lodash-move
const _toConsumableArray = arr => {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i]
    }
    return arr2
  } else {
    return Array.from(arr)
  }
}

/**
 * Moves an array item from one position in an array to another.
 * Note: This is a pure function so a new array will be returned, instead
 * of altering the array argument.
 * @param  {Array} array     Array in which to move an item.         (required)
 * @param  {Number} moveIndex The index of the item to move.          (required)
 * @param  {Number} toIndex   The index to move item at moveIndex to. (required)
 * @return {Array}           A new array with the item moved.
 */
export const move = (array, moveIndex, toIndex) => {
  const item = array[moveIndex]
  const length = array.length
  const diff = moveIndex - toIndex

  if (diff > 0) {
    // move left
    return [].concat(
      _toConsumableArray(array.slice(0, toIndex)),
      [item],
      _toConsumableArray(array.slice(toIndex, moveIndex)),
      _toConsumableArray(array.slice(moveIndex + 1, length)),
    )
  } else if (diff < 0) {
    // move right
    return [].concat(
      _toConsumableArray(array.slice(0, moveIndex)),
      _toConsumableArray(array.slice(moveIndex + 1, toIndex + 1)),
      [item],
      _toConsumableArray(array.slice(toIndex + 1, length)),
    )
  }
  return array
}
