import { Icon } from '@iconify/react'
import { Box, Form, Heading, Text } from 'grommet'
import React, { SyntheticEvent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useRegister } from '../../../state'
import { Button, Input } from '../../../ui'
import { useSize } from '../../../ui/useSize'
import { useBootstrap } from '../../../util/useBootstrap'
import Card from '../../Card'

import AuthError from './AuthError'

interface RegisterPayload {
  email: string
  username: string
  password: string
}

const neededKeys = ['email', 'username', 'password']

const Register = (): JSX.Element => {
  const [registerObj, setRegisterObj] = useState<RegisterPayload>()
  const size = useSize()
  const navigate = useNavigate()
  const register = useRegister()
  const bootstrap = useBootstrap()

  const submit = useCallback(
    async (e?: SyntheticEvent) => {
      e?.preventDefault()

      if (!(registerObj && neededKeys.every(key => Object.keys(registerObj).includes(key)))) {
        return toast.error(() => <AuthError error={{ status: 1000 }} />, {
          hideProgressBar: true,
          closeOnClick: true,
        })
      }

      await register(registerObj)
        .then(bootstrap)
        .then(() => navigate('/dashboard'))
        .catch(error => {
          if (error.status === 401)
            // Unauthorized on register - register is disabled
            return toast.error(() => <AuthError error={{ status: 1001 }} />, {
              hideProgressBar: true,
              closeOnClick: true,
            })

          if (error.status === 400)
            return toast.error(() => <AuthError error={{ ...error, status: 1002 }} />, {
              closeOnClick: true,
            })

          toast.error(() => <AuthError error={error} />, {
            hideProgressBar: true,
            closeOnClick: true,
          })
          navigate('/register')
        })
    },
    [bootstrap, navigate, register, registerObj],
  )

  return (
    <Box align="center" justify="center" fill>
      <Card>
        <span className="css_logo small" onClick={() => navigate('/')}>
          LUSORIUM
        </span>
        <Box direction="row" align="center">
          <Icon icon="fa-solid:sign-in-alt" fontSize={size === 'small' ? 30 : 38} />
          <Heading level={3} margin={{ left: size === 'small' ? 'medium' : 'small' }}>
            Sign Up
          </Heading>
        </Box>
        <Form onSubmit={submit}>
          <Input
            label="Username"
            onChange={e => setRegisterObj({ ...registerObj, username: e.target.value })}
            value={registerObj?.username || ''}
            type="text"
            margin={{ bottom: 'medium' }}
          />
          <Input
            label="Email"
            onChange={e => setRegisterObj({ ...registerObj, email: e.target.value })}
            value={registerObj?.email || ''}
            type="email"
            margin={{ bottom: 'medium' }}
          />
          <Input
            label="Password"
            onChange={e => setRegisterObj({ ...registerObj, password: e.target.value })}
            value={registerObj?.password || ''}
            type="password"
          />
          <Text size="xsmall" margin={{ top: 'small' }} color="warning">
            Currently in alpha mode. No sign up possible.
          </Text>
          <Box direction="row" justify="between" align="center" margin={{ top: 'medium' }}>
            <Button label="Sign up" type="submit" color="brand" />
            <Button label="Login" onClick={() => navigate('/login')} color="accent-1" />
          </Box>
        </Form>
      </Card>
    </Box>
  )
}

export default Register
