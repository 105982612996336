import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { Blog, Home, Login, Register, Dashboard } from '../components/pages'
import { useLoggedIn } from '../state'

import { AppRouter } from '.'
import { Layout } from './Layout'

const RootRouter = () => {
  const isLoggedIn = useLoggedIn()
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {!isLoggedIn ? (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/apps/*" element={<AppRouter />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
          <Route path="/blog" element={<Blog />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default RootRouter
