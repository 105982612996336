// https://dev-to-dev.com/how-to-re-render-large-lists-optimally-when-states-change-in-react/
// https://github.com/breezertwo/whatdoumeme/blob/main/packages/client/src/hooks/useConnection.ts

import { atom, selector, useRecoilState, useRecoilValue } from 'recoil'

import { userAtom } from '../../../state/user'

export enum GameState {
  WAITING = 0,
  LOBBY = 1,
  QUESTION = 2,
  ANSWERS = 3,
  END = 5,
  ANSWERED = 4,
  UNKOWN = -1,
}

export interface AvailebleGame extends Omit<GameObject, 'players'> {
  players: number
}

export interface GameObject {
  gameId: string
  gameType: 'auto' | 'quizmaster'
  players: Player[]
  gameState: GameState
  public: boolean
  host: string
}

export interface Player {
  userId?: string
  username: string
  avatar: string
  connected: boolean
  points: number
  hasAnswerd: boolean
}

const quizGameObject = atom<GameObject>({
  key: 'quizGameObject',
  default: {
    gameId: null,
    gameType: null,
    players: null,
    gameState: GameState.UNKOWN,
    public: null,
    host: null,
  },
})

export const useGetGameInfo = () => useRecoilValue(quizGameObject)
export const useGameInfo = () => useRecoilState(quizGameObject)

const playerList = selector<Player[]>({
  key: 'playerList',
  get: ({ get }) => get(quizGameObject).players,
})
export const usePlayerList = () => useRecoilValue(playerList)

const player = selector<Player>({
  key: 'player',
  get: ({ get }) => {
    const user = get(userAtom)
    return get(quizGameObject).players.find(player => player.username === user.username)
  },
})
export const usePlayer = () => useRecoilValue(player)

export const useIsHost = () => {
  const [game] = useGameInfo()
  const player = usePlayer()
  return game.host === player.username
}
