const baseDefault: String =
  process.env.NODE_ENV !== 'development' ? 'https://api-beta.lusorium.io' : 'http://localhost'
interface Settings {
  BASE_API_PATH: String
  BASE_WS_PATH: String
}

export default {
  BASE_API_PATH:
    process.env.NODE_ENV !== 'development' ? baseDefault + '/v1' : baseDefault + ':9000/v1',
  BASE_WS_PATH: process.env.NODE_ENV !== 'development' ? baseDefault : baseDefault + ':81/',
} as Settings
