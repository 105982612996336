import { Box, Heading, Text } from 'grommet'
import React from 'react'
import styled from 'styled-components'

import './post.scss'

export type BlogPost = {
  author: string
  date: string
  tags: string[]
  title: string
  text: string
  image: string
  _id: string
}

interface BlogPostProps {
  post: BlogPost
}

const AbsoluteBox = styled(Box)`
  position: absolute;
`

const BlogPostElement = ({ post }: BlogPostProps) => {
  const date = new Date(post.date).toDateString()

  return (
    <div className="blog-grid">
      <div className="blog-grid-header">
        <span className="author">{post.author}</span>
        <span className="spacer">|</span>
        <span className="blog-grid-date h5">{date}</span>
      </div>

      <div className="blog-grid-image">
        <img src={post.image} />
        <AbsoluteBox>
          {post.tags.map((tag, index) => (
            <Box
              key={index}
              margin="xsmall"
              pad={{ top: '8px', bottom: '4px', horizontal: '12px' }}
              round="small"
              background="rgba(0, 0, 34, 0.58)"
            >
              <Text size="xsmall" color="#ffc850">
                {tag}
              </Text>
            </Box>
          ))}
        </AbsoluteBox>
      </div>
      <Heading level={4} fill>
        {post.title}
      </Heading>
      <Text size="small">{post.text}</Text>
    </div>
  )
}

export default BlogPostElement
