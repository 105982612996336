import { Box } from 'grommet'
import React, { useState } from 'react'

import Card from '../../../components/Card'
import { useUser } from '../../../state'
import { Button, Input } from '../../../ui'
import { useCreateLobby, useJoinGame } from '../../../util/socket'
import CustomContent from '../components/CustomContent'
import QuizGameList from '../components/GameList'

const GameStage = () => {
  const [gameId, setGameId] = useState('')
  const user = useUser()
  const createLobby = useCreateLobby()
  const joinGame = useJoinGame()

  const handleCreate = () => createLobby()

  const handleJoin = () => joinGame({ gameId, playerId: user._id })

  return (
    <Box as="section" align="center" flex="grow">
      <Card direction="column">
        <Button label="Create new game" onClick={handleCreate} />
        <div style={{ borderBottom: '2px solid black', margin: '1em 0', flexGrow: 1 }} />
        <Input label={'ENTER ROOM ID'} onChange={e => setGameId(e.target.value)} />
        <Button label="Join game" onClick={() => handleJoin()} margin={{ top: 'small' }} />
      </Card>
      <QuizGameList />
      <CustomContent />
    </Box>
  )
}

export default GameStage
