/*
#EA8061
#061423
#9A3930
#FBEEC3
#F4CFA4
#626C58
*/

const colorPalette = {
  primary: '#EA8061',
  secondary: '#061423',
  tertiary: '#9A3930',
  quaternary: '#FBEEC3',
  quinary: '#F4CFA4',
  senary: '#626C58',
}

export const colors = {
  fontColor: '#fcb42a',
  backgoundColor: '#031323',
  primaryColor: '#f8874a',
  primaryColorDarkend: '#b86b3b',
  secondaryColor: '#a03e31',
  bgColorLight: '#012a77',
  boardColor: '#2121de',
  darkerPrimary: '#faa301',
  orangeShadow: '#ebbf6f',
  colorLight: '#fff',
  lightGrey: '#f0f0f0',
  grey: '#ccc',
  darkGrey: '#333',
  gold: '	#FFD700',
  ...colorPalette,
} as const

export const fontFamily = '"Press Start 2P"'
