export interface User {
  /**
   * @deprecated Use userId on server side
   */
  _id: string
  username: string
  name: string
  email: string
  roles: string[]
  avatar?: string
}
