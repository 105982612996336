const h1 = {
  fontWeight: 500,
  fontSize: 24,
  lineHeight: '44px',
  letterSpacing: 0.35,
  margin: 0,
}

const h2 = {
  fontWeight: 500,
  fontSize: 22,
  lineHeight: '44px',
  letterSpacing: 0.35,
  margin: 0,
}

const h3 = {
  fontWeight: 500,
  fontSize: 20,
  lineHeight: '22px',
  margin: 0,
}

const body1 = {
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '22px',
  letterSpacing: -0.41,
}

const subhead = {
  fontSize: 14,
  lineHeight: '22px',
  margin: 0,
}

export const fonts = {
  h1,
  h2,
  h3,
  body1,
  subhead,
} as const
