import { Avatar, Box, Text } from 'grommet'
import React from 'react'
import styled from 'styled-components'

import { useUser } from '../../../state'
import Card from '../../Card'

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const UserInfo = () => {
  const user = useUser()

  return (
    <Card direction="row" ariaDescribedby="Box with user details">
      <Avatar
        size="xlarge"
        src={`data:image/svg+xml;utf8,${encodeURIComponent(user.avatar)}`}
        margin={{ right: 'small', bottom: 'small' }}
      />
      <Box>
        <Box direction="column" pad="small">
          <Text>Username:</Text>
          <Text size="small">{user.username}</Text>
        </Box>
        <Box direction="column" pad="small">
          <Text>Email:</Text>
          <StyledText size="small">{user.email}</StyledText>
        </Box>
      </Box>
    </Card>
  )
}

export default UserInfo
