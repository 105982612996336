import { Box } from 'grommet'
import React from 'react'

import { useAuth } from '../../../state'
import { Settings } from '../../../util'
import client from '../../../util/client'
import { AsyncStatus, useAsync } from '../../../util/useAsync'
import LoadingSpinner from '../../LoadingSpinner'

import BlogPostElement, { BlogPost } from './post'

const Blog = (): JSX.Element => {
  const { token } = useAuth()
  const { run, status, value } = useAsync<BlogPost[]>()

  const errorPost = {
    author: 'system',
    date: `${new Date()}`,
    tags: ['404'],
    title: 'It looks like you are lost',
    text: 'Somthing went wrong or nothing to see here right now. Come back later.',
    image: Settings.BASE_API_PATH + '/assets/img/nobody_11012023_gen',
    _id: '404',
  }

  React.useEffect(() => {
    run(client('blog/all', { token }).catch(e => console.log(e)))
  }, [run, token])

  if (status === AsyncStatus.Pending) return <LoadingSpinner />

  return (
    <Box align="center" fill>
      {value?.length > 0 ? (
        value.map((post, index) => <BlogPostElement key={index} post={post} />)
      ) : (
        <BlogPostElement post={errorPost} />
      )}
    </Box>
  )
}

export default Blog
