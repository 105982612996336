import { Icon } from '@iconify/react'
import { Box, Form, Heading } from 'grommet'
import React, { SyntheticEvent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useLogin } from '../../../state'
import { Button, Input } from '../../../ui'
import { useSize } from '../../../ui/useSize'
import { useBootstrap } from '../../../util/useBootstrap'
import Card from '../../Card'

import AuthError from './AuthError'

const Login = (): JSX.Element => {
  const [username, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const size = useSize()
  const navigate = useNavigate()
  const login = useLogin()
  const bootstrap = useBootstrap()

  const onRegister = useCallback(() => {
    navigate('/register')
  }, [navigate])

  const submit = useCallback(
    async (e?: SyntheticEvent) => {
      e?.preventDefault()

      if (!username || !password) {
        return toast.error(() => <AuthError error={{ status: 1000 }} />, {
          hideProgressBar: true,
          closeOnClick: true,
        })
      }

      await login({ username, password })
        .then(bootstrap)
        .then(() => navigate('/dashboard'))
        .catch(error => {
          toast.error(() => <AuthError error={error} />, {
            hideProgressBar: true,
            closeOnClick: true,
          })
          navigate('/login')
        })
    },
    [bootstrap, login, navigate, password, username],
  )

  return (
    <Box align="center" justify="center" fill>
      <Card margin="small">
        <span className="css_logo small" onClick={() => navigate('/')}>
          LUSORIUM
        </span>
        <Box direction="row" align="center">
          <Icon icon="fa-solid:sign-in-alt" fontSize={size === 'small' ? 30 : 38} />
          <Heading level={3} margin={{ left: size === 'small' ? 'medium' : 'small' }}>
            Login
          </Heading>
        </Box>
        <Form onSubmit={submit}>
          <Input
            label="Username"
            onChange={e => setEmail(e.target.value)}
            value={username}
            margin={{ bottom: 'medium' }}
          />
          <Input
            label="Password"
            onChange={e => setPassword(e.target.value)}
            value={password}
            type="password"
          />
          <Box direction="row" justify="between" align="center" margin={{ top: 'medium' }}>
            <Button label="Login" type="submit" color="brand" />
            <Button label="Sign up" onClick={onRegister} color="accent-1" />
          </Box>
        </Form>
      </Card>
    </Box>
  )
}

export default Login
