import { useQuery } from '@tanstack/react-query'
import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

import { useAuth } from '../../../state'
import client from '../../../util/client'

import { GameState, useGetGameInfo } from './gameState'

export interface StandartQuestionType {
  questionText: string
  answers: string[]
  questionMediaSrc?: QuestionMediaSrc
}

export interface GuessQuestionType {
  questionText: string
  questionMediaSrc?: QuestionMediaSrc
}

export interface TextQuestionType {
  questionText: string
  questionMediaSrc?: QuestionMediaSrc
  value: number
}

export interface SortQuestionType {
  questionText: string
  questionMediaSrc?: QuestionMediaSrc
  orderMode: 'lth' | 'htl'
  answers: string[]
}

export type QuestionObject = { _id: string; category: string; timeLimit?: number } & (
  | {
      questionType: QuestionType.STANDART
      data: StandartQuestionType
    }
  | {
      questionType: QuestionType.GUESS
      data: GuessQuestionType
    }
  | {
      questionType: QuestionType.TEXT
      data: TextQuestionType
    }
  | {
      questionType: QuestionType.SORT
      data: SortQuestionType
    }
)

export enum QuestionType {
  STANDART = 0,
  GUESS = 1,
  TEXT = 2,
  SORT = 3,
}

export type Question =
  | StandartQuestionType
  | GuessQuestionType
  | TextQuestionType
  | SortQuestionType

type QuestionMediaSrc = String
//type Answer = number | string | string[]

const questionObject = atom<QuestionObject>({
  key: 'questionObject',
  default: {
    _id: null,
    questionType: null,
    category: null,
    data: null,
    timeLimit: null,
  },
})
export const useQuestion = () => useRecoilValue(questionObject)
export const useSetQuestion = () => useSetRecoilState(questionObject)

export const useGetQuestion = () => {
  const { gameId, gameState } = useGetGameInfo()
  const { token } = useAuth()
  return useQuery({
    queryKey: ['getQuestions'],
    queryFn: () => client<QuestionObject>(`quiz/getQuestion/${gameId}`, { token }),
    enabled: gameState === GameState.QUESTION,
    cacheTime: 0,
  })
}
