/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'

export const useOutsideDetect = (
  ref: React.MutableRefObject<any>,
  onOutsideClick: (event?: MouseEvent) => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick(event)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onOutsideClick, ref])
}
