import { Box } from 'grommet'
import React from 'react'

import GameList from './GameList'
import UserInfo from './UserInfo'

const Dashboard = () => {
  return (
    <Box flex="grow" align="center">
      <UserInfo />
      <GameList />
    </Box>
  )
}

export default Dashboard
