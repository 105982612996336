import { Text } from 'grommet'
import React from 'react'

import Card from '../../../components/Card'
import { useTimer } from '../../../util'

const WatingForAnswers = () => {
  const timer = useTimer(5)

  return (
    <Card>
      <Text>All Players have answerd. Next Question in {timer} seconds...</Text>
    </Card>
  )
}

export default WatingForAnswers
