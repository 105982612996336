import { Icon } from '@iconify/react'
import { useSprings, animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { Box, Text } from 'grommet'
import { MarginType } from 'grommet/utils'
import clamp from 'lodash/clamp'
import React, { useRef } from 'react'

import { move } from '../util'

const rowHeight = 100

const fn =
  (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
  (index: number) =>
    active && index === originalIndex
      ? {
          y: curIndex * (rowHeight / 2) + y,
          scale: 1.02,
          zIndex: 1,
          shadow: 15,
          immediate: (key: string) => key === 'y' || key === 'zIndex',
        }
      : {
          y: order.indexOf(index) * (rowHeight / 2),
          scale: 1,
          zIndex: 0,
          shadow: 1,
          immediate: false,
        }

interface Props {
  items: string[]
  margin?: MarginType
  onChange?: (newOrder: string[]) => void
}

const DraggableList = ({ items, margin, onChange }: Props) => {
  const order = useRef(items.map((_, index) => index))

  const [springs, refs] = useSprings(items.length, fn(order.current))

  const drag = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    const curIndex = order.current.indexOf(originalIndex)

    const curRow = clamp(Math.round((curIndex * rowHeight + y) / rowHeight), 0, items.length - 1)
    const newOrder = move(order.current, curIndex, curRow)

    refs.start(fn(newOrder, active, originalIndex, curIndex, y))

    if (!active) {
      order.current = newOrder
      onChange?.(newOrder.map(index => items[index]))
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any

  return (
    <Box
      margin={margin}
      style={{ position: 'relative', height: items.length * (rowHeight / 2) - 8.5 }}
    >
      {springs.map(({ zIndex, shadow, y, scale }, i) => (
        <Box
          as={animated.div}
          key={i}
          align="center"
          justify="start"
          fill="horizontal"
          direction="row"
          background="accent-2"
          pad={{ horizontal: 'small' }}
          round="small"
          aria-grabbed={zIndex.to(x => x === 1)}
          style={{
            position: 'absolute',
            touchAction: 'none',
            transformOrigin: '50% 50% 0px',
            height: (rowHeight / 2) * 0.85,
            boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
            cursor: 'move',
            y,
            scale,
            zIndex,
          }}
          {...drag(i)}
        >
          <Icon icon={`mdi:number-${i + 1}-circle`} fontSize={26} />
          <Text size="small" margin={{ left: 'small' }}>
            {items[i]}
          </Text>
        </Box>
      ))}
    </Box>
  )
}

export default DraggableList
