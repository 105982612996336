import React from 'react'
import { Route, Routes } from 'react-router-dom'

import QuizIO from '../apps/quizio'
import EditQuestion from '../apps/quizio/components/EditQuestion'
import EditQuestionSet from '../apps/quizio/components/EditQuestionSet'

const Apps = () => {
  //const QuizIO = React.lazy(() => import('../apps/quizio'))
  //const EditQuestionSet = React.lazy(() => import('../apps/quizio/components/EditQuestionSet'))

  return (
    <Routes>
      <Route path="/quizio">
        <Route index element={<QuizIO />} />
        <Route path="edit-question-set" element={<EditQuestionSet />} />
        <Route path="edit-question" element={<EditQuestion />} />
      </Route>
    </Routes>
  )
}

export default Apps
