import { Header, Text } from 'grommet'
import React from 'react'

import NavigationItems from './NavigationItems'

export type Entry = {
  name: string
  path: string
  locked?: boolean
}

export const Navigation = (): JSX.Element => {
  const entries: Entry[] = [
    { name: 'Blog', path: 'blog' },
    { name: 'Dashboard', path: 'dashboard', locked: true },
  ]

  return (
    <Header background="none" pad="medium" height="xsmall" role="presentation">
      <Text size="xxlarge" color="white">
        {location.pathname.includes('quizio') ? 'QUIZ.IO' : 'LUSORIUM'}
      </Text>
      <NavigationItems entiries={entries} />
    </Header>
  )
}
