import { Button as BaseButton, ButtonProps as BaseButtonProps } from 'grommet'
import { ColorType, HoverIndicatorType } from 'grommet/utils'
import React, { SyntheticEvent } from 'react'

interface ButtonProps extends BaseButtonProps {
  hoverColor?: ColorType
  onClick?: (event: SyntheticEvent) => void
}

const Button = ({
  color = 'light-1',
  hoverColor = 'focus',
  disabled = false,
  size = 'small',
  onClick,
  ...props
}: ButtonProps) => {
  return (
    <BaseButton
      size={size}
      color={color}
      disabled={disabled}
      hoverIndicator={{ background: { color: hoverColor } } as HoverIndicatorType}
      pad={!props.icon ? { top: '6px', bottom: '3px' } : undefined} // Temp fix for button text alignment
      onClick={onClick}
      {...props}
    />
  )
}

export default Button
