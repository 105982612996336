import { Box, Text } from 'grommet'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { Button, Input } from '../../../../ui'
import { useAnswerQuestion } from '../../../../util/socket'
import type { GuessQuestionType } from '../../state/questionState'

interface GuessQuestionProps {
  data: GuessQuestionType
}

const isNumeric = (value: string) => {
  return /^\d+$/.test(value)
}

const GuessQuestion = ({ data }: GuessQuestionProps) => {
  const [guess, setGuess] = useState(null)

  const sendAnswer = useAnswerQuestion()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setGuess(e.target.value)

  const answerQuestion = () => {
    if (!guess) return toast.info('You have to enter a guess')
    if (!isNumeric(guess)) return toast.info('You have to enter a number')

    sendAnswer(guess)
  }

  return (
    <>
      <Box pad={'medium'} round="xsmall" background={'#E6C8A9'}>
        <Text>{data.questionText}</Text>
      </Box>
      <Input margin={{ vertical: 'small' }} label={'Add a guess here'} onChange={handleChange} />
      <Button label="Answer Question" onClick={answerQuestion} />
    </>
  )
}

export default GuessQuestion
