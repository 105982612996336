import { Icon } from '@iconify/react'
import { useQuery } from '@tanstack/react-query'
import { Box, Text } from 'grommet'
import React, { useState } from 'react'

import { Card } from '../../../components'
import { useAuth } from '../../../state'
import { mainTheme } from '../../../style'
import { Button, Dropdown } from '../../../ui'
import client from '../../../util/client'
import { useLeaveGame, useSetQuestionSet, useStartGame } from '../../../util/socket'
import { useGetGameInfo, useIsHost, usePlayerList } from '../state/gameState'

const Lobby = () => {
  const [gameReady, setGameReady] = useState(false)
  const { token } = useAuth()

  const { gameId, host } = useGetGameInfo()
  const playerList = usePlayerList()
  const leaveGame = useLeaveGame()
  const startGame = useStartGame()
  const isHost = useIsHost()
  const setQuestionSet = useSetQuestionSet()

  const { isLoading, data } = useQuery({
    queryKey: ['questions'],
    queryFn: () => client('questions', { token }),
  })

  const onSetQuestionSet = (questionSetId: string) => {
    setQuestionSet(questionSetId)
    setGameReady(true)
  }

  return (
    <Box as="section" align="center" flex="grow">
      <Card>
        <Box direction="row" justify="between" margin={{ bottom: 'small' }}>
          <Text>Lobby ID:</Text>
          <Text>{gameId}</Text>
        </Box>
        <div
          style={{
            backgroundColor: 'black',
            boxShadow: '0.1em 0.1em 0.2em 0px rgb(33 33 33 / 70%)',
            height: 3,
            marginBottom: 10,
          }}
        />
        {playerList.map(player => (
          <Box
            direction="row"
            justify="between"
            margin={{ vertical: 'small' }}
            key={player.username}
          >
            <Text>{player.username}</Text>
            <Box direction="row" align="center" gap="xsmall">
              {player.username === host && (
                <Icon
                  icon="icon-park-solid:crown-three"
                  color={mainTheme.global.colors['gold']}
                  fontSize={24}
                />
              )}
              {player.connected ? (
                <Icon icon="ph:wifi-high-bold" color={'green'} fontSize={24} />
              ) : (
                <Icon icon="ph:wifi-x-bold" color={'red'} fontSize={24} />
              )}
            </Box>
          </Box>
        ))}
        <Box margin={{ top: 'medium' }}>
          {isHost && (
            <>
              <Dropdown
                placeholder="Select quiz"
                data={!isLoading ? data : []}
                onChange={onSetQuestionSet}
              />
              <Button
                label="Start game"
                onClick={startGame}
                disabled={!gameReady}
                margin={{ bottom: 'xsmall' }}
              />
            </>
          )}
          <Button label="Leave game" hoverColor="accent-1" onClick={leaveGame} />
        </Box>
      </Card>
    </Box>
  )
}

export default Lobby
