import {
  Box,
  Text,
  TextArea as GrommetTextArea,
  TextAreaExtendedProps as GrommetBaseProps,
} from 'grommet'
import { MarginType } from 'grommet/utils'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { mainTheme } from '../style'

export interface TextAreaProps extends GrommetBaseProps {
  label: string
  labelBackground?: string
  margin?: MarginType
}

const StyledText = styled(Text)`
  position: absolute;
  left: 8px;
  top: 10px;
  padding: 4px;
  background-color: ${props => props.labelBackground};
  transition: all 0.5s cubic-bezier(0.33, 1, 0.68, 1) 0s;
  pointer-events: none;
  ${props =>
    props.size === '10px'
      ? `
      transform: translate(2px, -17px);
      color: ${
        !props.disabled ? mainTheme.global.colors['brand'] : mainTheme.global.colors['grey']
      }; };
      `
      : ''}
`

const TextArea = ({
  label,
  labelBackground = mainTheme.global.colors['light-1'],
  onChange,
  onFocus,
  onBlur,
  margin,
  value,
  disabled,
  ...props
}: TextAreaProps) => {
  const [focused, setFocused] = useState(false)
  const [hasVal, setHasValue] = useState(value !== '' && value !== undefined)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const textarea = textareaRef.current
    const isMultiLine = () => (textarea.scrollHeight > textarea.clientHeight ? true : false)

    const updateSize = () => {
      if (textarea.value === '' || !isMultiLine()) textarea.style.height = '47.5px' // Input height
      if (isMultiLine()) textarea.style.height = `${textarea.scrollHeight}px`
    }

    updateSize()
    textarea.addEventListener('input', updateSize, false)

    return () => {
      textarea.removeEventListener('input', updateSize, false)
    }
  }, [])

  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    setFocused(true)
    onFocus?.(e)
  }

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    setFocused(false)
    onBlur?.(e)
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHasValue(e.target.value !== '' && e.target.value !== undefined)
    onChange?.(e)
  }

  return (
    <Box style={{ position: 'relative' }} margin={margin} role="presentation">
      <GrommetTextArea
        ref={textareaRef}
        size="small"
        aria-label={label}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleOnChange}
        disabled={disabled}
        value={value}
        resize={false}
        {...props}
      />
      <StyledText
        size={focused || hasVal ? '10px' : 'small'}
        labelBackground={labelBackground}
        disabled={disabled}
      >
        {label}
      </StyledText>
    </Box>
  )
}
export default TextArea
