import { Box, Grid, Text } from 'grommet'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { Button } from '../../../../ui'
import { useSize } from '../../../../ui/useSize'
import { useAnswerQuestion } from '../../../../util/socket'
import type { StandartQuestionType } from '../../state/questionState'

interface StandartQuestionProps {
  data: StandartQuestionType
}

const StandartQuestion = ({ data }: StandartQuestionProps) => {
  const size = useSize()
  const sendAnswer = useAnswerQuestion()

  const [selected, setSelected] = useState(-1)
  const hasSelected = selected != -1

  const answerQuestion = () => {
    if (!hasSelected) return toast.info('You have to select an answer')
    sendAnswer(selected)
  }

  return (
    <Box as="section" direction="column" role="presentation">
      <Box
        pad={'medium'}
        round="xsmall"
        background={'#E6C8A9'}
        aria-describedby="Question"
        tabIndex={0}
      >
        <Text>{data.questionText}</Text>
      </Box>
      <Grid
        gap="small"
        columns={{ count: size === 'large' ? 4 : size === 'medium' ? 2 : 1, size: 'auto' }}
        margin={{ vertical: 'small' }}
        aria-label="Answers"
      >
        {data.answers.map((option, idx) => (
          <Box
            key={idx}
            onClick={() => setSelected(idx)}
            background={selected === idx ? 'accent-4' : 'accent-2'}
            round="xsmall"
            justify="center"
            align="center"
            pad="small"
            role="button"
            aria-pressed={selected === idx}
          >
            <Text size="small">{option}</Text>
          </Box>
        ))}
      </Grid>
      <Button label="Answer Question" onClick={answerQuestion} />
    </Box>
  )
}

export default StandartQuestion
