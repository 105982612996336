import { Box, Text } from 'grommet'
import React from 'react'

import Card from '../../../components/Card'
import { Button } from '../../../ui'
import { useLeaveGame } from '../../../util/socket'
import PlayerList from '../components/PlayerList'

const End = () => {
  const leaveGame = useLeaveGame()

  return (
    <Box as="section" align="center" flex="grow">
      <Button label="Leave game" alignSelf="end" hoverColor="accent-1" onClick={leaveGame} />
      <Card>
        <Text>Game ended. See the Results</Text>
      </Card>
      <PlayerList />
    </Box>
  )
}

export default End
