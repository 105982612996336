import { Icon } from '@iconify/react'
import { Box, Heading } from 'grommet'
import React, { useMemo } from 'react'

import { LoadingSpinner } from '../../../../components'
import Card from '../../../../components/Card'
import { Button } from '../../../../ui'
import { useLeaveGame } from '../../../../util/socket'
import PlayerList from '../../components/PlayerList'
import { QuestionType, useGetQuestion } from '../../state/questionState'

import GuessQuestion from './GuessQuestion'
import SortQuestion from './SortQuestion'
import StandartQuestion from './StandartQuestion'
import TextQuestion from './TextQuestion'

const Question = () => {
  const leaveGame = useLeaveGame()
  const { data: question, isLoading } = useGetQuestion()

  const renderQuestion = useMemo(() => {
    if (!question) return null

    switch (question.questionType) {
      case QuestionType.STANDART:
        return <StandartQuestion data={question.data} />
      case QuestionType.GUESS:
        return <GuessQuestion data={question.data} />
      case QuestionType.TEXT:
        return <TextQuestion data={question.data} />
      case QuestionType.SORT:
        return <SortQuestion data={question.data} />
      default:
        return <p>Unknown question type</p>
    }
  }, [question])

  return (
    <Box as="section" align="center" wrap={false} flex="grow" role="presentation">
      <Button label="Leave game" onClick={leaveGame} alignSelf="end" hoverColor="accent-1" />
      {isLoading || !question ? (
        <LoadingSpinner fill={false} />
      ) : (
        <>
          <Card wrap={false}>
            <Box
              direction="row"
              align="center"
              justify="between"
              margin={{ top: 'none', bottom: 'medium' }}
              role="presentation"
            >
              <Heading margin="none" level={4}>
                Category: {question.category}
              </Heading>
              <Icon icon="mdi:chat-question" fontSize={42} />
            </Box>
            {renderQuestion}
          </Card>
          <PlayerList />
        </>
      )}
    </Box>
  )
}

export default Question
