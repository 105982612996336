import { Icon } from '@iconify/react'
import { Box, Text } from 'grommet'
import React from 'react'

import Card from '../../../components/Card'
import { mainTheme } from '../../../style'
import { useGameInfo } from '../state/gameState'

const PlayerList = () => {
  const [gameObject] = useGameInfo()

  return (
    <Card>
      <Box direction="row" justify="between" align="center" margin={{ bottom: 'medium' }}>
        <Text size="medium">Players</Text>
        <Icon icon="mdi:user-circle" fontSize={36} />
      </Box>
      {gameObject.players.map((player, idx) => (
        <Box direction="row" justify="between" align="center" key={idx}>
          <Box direction="row" align="center">
            <Text size="small" margin={{ right: 'small' }}>
              {player.username}
            </Text>
            {player.username === gameObject.host && (
              <Icon
                icon="icon-park-solid:crown-three"
                color={mainTheme.global.colors['gold']}
                fontSize={20}
              />
            )}
          </Box>
          <Box direction="row" align="center" gap="small">
            <Text size="small">{player.points} Pts</Text>
            <Icon
              icon="material-symbols:check-circle-rounded"
              color={player.hasAnswerd ? 'green' : 'red'}
              fontSize={20}
            />
            {player.connected ? (
              <Icon icon="ph:wifi-high-bold" color={'green'} fontSize={20} />
            ) : (
              <Icon icon="ph:wifi-x-bold" color={'red'} fontSize={20} />
            )}
          </Box>
        </Box>
      ))}
    </Card>
  )
}

export default PlayerList
