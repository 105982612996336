import { Icon } from '@iconify/react'
import { Box, Heading, Text } from 'grommet'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import Card from '../../../components/Card'
import { Button } from '../../../ui'
import { useGetUserQuestionSets } from '../util/questions'

interface CustomContentProps {
  enabled?: boolean
}

const CustomContent = ({ enabled = true }: CustomContentProps) => {
  const navigate = useNavigate()
  const { loading, data } = useGetUserQuestionSets()

  const onEditQuestionSet = (id: string) => {
    navigate(`/apps/quizio/edit-question-set`, { state: { id } })
  }

  const onCreateQuestionSet = () => {
    window.alert('Function not implemented.')
  }

  return (
    <Card>
      <Heading margin={{ top: 'none', bottom: 'small' }} level={4}>
        CUSTOM CONTENT
      </Heading>
      {enabled ? (
        <>
          <Card
            direction="column"
            margin={{ vertical: 'small' }}
            shadow={false}
            background="#E0C9A6 "
          >
            <Heading margin={{ top: 'none' }} level={5}>
              My Question Sets
            </Heading>
            {!loading && data?.length ? (
              data.map((questionSet, i) => (
                <Box
                  key={i}
                  margin={{ vertical: 'xsmall' }}
                  direction="row"
                  justify="between"
                  pad="xsmall"
                  round="xsmall"
                  gap="small"
                  background="light-2"
                  elevation="xsmall"
                >
                  <Box direction="row" justify="start" gap="xsmall" align="center">
                    <Box flex={false}>
                      <Icon icon="fluent:quiz-new-20-filled" fontSize={28} />
                    </Box>
                    <Text alignSelf="center" size="small" truncate="tip">
                      {questionSet.name}
                    </Text>
                  </Box>
                  <Box direction="row" justify="end" gap="small" align="center" flex={false}>
                    <Box
                      title="Edit Question Set"
                      hoverIndicator={{ elevation: 'small', background: 'accent-2' }}
                      onClick={() => onEditQuestionSet(questionSet._id)}
                    >
                      <Icon icon="bxs:edit" />
                    </Box>
                    <Icon
                      icon="streamline:interface-id-iris-scan-check-identification-retina-security-approved-success-iris-scan-eye-login"
                      color={questionSet.approved ? 'green' : 'red'}
                      aria-label={questionSet.approved ? 'Approved' : 'Not Approved'}
                    />
                    <Box
                      direction="row"
                      width={{ min: '80px' }}
                      gap="xsmall"
                      justify="start"
                      align="center"
                    >
                      <Icon icon="ic:baseline-thumb-up" />
                      <Text textAlign="center" size="xsmall">
                        {questionSet.likes.toString()}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Text size="xsmall">No own sets, yet.</Text>
            )}
          </Card>
          <Button
            label="Create Question Set"
            size="small"
            icon={<Icon icon="fluent:quiz-new-20-filled" fontSize={28} />}
            onClick={() => onCreateQuestionSet()}
          />
        </>
      ) : (
        <Box direction="row" justify="between" align="center">
          <Text size="xsmall">Disabled for your usergroup currently.</Text>
        </Box>
      )}
    </Card>
  )
}

export default CustomContent
