import React from 'react'

import { useInitiateSocket } from '../../util/socket'

import { GameStage, Lobby } from './screens'
import Answers from './screens/Answers'
import End from './screens/End'
import Question from './screens/Questions/Question'
import { GameState, useGameInfo } from './state/gameState'

const QuizIO = (): JSX.Element => {
  const [{ gameState }] = useGameInfo()
  useInitiateSocket()

  switch (gameState) {
    case GameState.UNKOWN:
      return <GameStage />
    case GameState.LOBBY:
      return <Lobby />
    case GameState.QUESTION:
      return <Question />
    case GameState.ANSWERED:
    case GameState.ANSWERS:
      return <Answers />
    case GameState.END:
      return <End />
    default:
      return <p>404</p>
  }
}

export default QuizIO
