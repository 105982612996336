import { useQuery } from '@tanstack/react-query'
import { deepMerge } from 'grommet/utils'
import { isEqual } from 'lodash'
import { toast } from 'react-toastify'
import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValueLoadable,
} from 'recoil'

import { auth, useAuth } from '../../../state'
import client, { Method } from '../../../util/client'
import { AvailebleGame } from '../state/gameState'
import { QuestionObject } from '../state/questionState'

export const useGetAvailableGameList = () => {
  const { token } = useAuth()

  return useQuery({
    queryKey: ['gameList'],
    queryFn: () => client<AvailebleGame[]>('quiz/games/list', { token }),
    staleTime: 1000,
  })
}

interface QuestionSetBase {
  _id: string
  name: string
  desc: string
  author: string
  authorId: string
  likes: number
  approved: boolean
}

const userQuestionSets = atom<QuestionSetBase[]>({
  key: 'userQuestionSets',
  default: selector({
    key: 'userQuestionSets/Default',
    get: async ({ get }) => {
      const { token } = get(auth)
      const data = await client<QuestionSetBase[]>('questions/user-set', { token })
      return data
    },
  }),
})

const getUserQuestionSetById = selectorFamily({
  key: 'getUserQuestionSetById',
  get:
    (id: string) =>
    ({ get }) => {
      const objectList = get(userQuestionSets)
      return objectList.find(obj => obj._id === id)
    },
})

export const useGetUserQuestionSetById = (id: string) => {
  const loadable = useRecoilValueLoadable(getUserQuestionSetById(id))

  return {
    data: loadable.state === 'hasValue' ? loadable.contents : undefined,
    error: loadable.state === 'hasError' ? loadable.contents : undefined,
    loading: loadable.state === 'loading',
  }
}

export const useGetUserQuestionSets = () => {
  const loadable = useRecoilValueLoadable(userQuestionSets)

  return {
    data: loadable.state === 'hasValue' ? loadable.contents : undefined,
    error: loadable.state === 'hasError' ? loadable.contents : undefined,
    loading: loadable.state === 'loading',
  }
}

export const useModifyQuestionSet = () =>
  useRecoilCallback(
    ({ set, reset, snapshot }) =>
      async (setId: string, update: Partial<QuestionSetBase>) => {
        const { token } = await snapshot.getPromise(auth)
        const currSets = await snapshot.getPromise(userQuestionSets)
        const prevSet = await snapshot.getPromise(getUserQuestionSetById(setId))

        let shouldUpdate = false

        const updatedSets = currSets.map(currSet => {
          if (currSet._id !== setId) return currSet
          if (isEqual(prevSet, { ...currSet, ...update })) return currSet

          shouldUpdate = true
          return { ...currSet, ...update }
        })

        if (!shouldUpdate) return

        try {
          set(userQuestionSets, updatedSets)
          await client(`questions/set/${setId}`, {
            token,
            method: Method.PATCH,
            data: JSON.stringify(update),
          })

          toast.success('Question set saved')
        } catch (error) {
          reset(userQuestionSets)

          toast.error('Failed to update question set.')
          return Promise.reject(error)
        }
      },
    [],
  )

export const questionsDetails = atomFamily<QuestionObject[], string>({
  key: 'questionsDetails',
  default: selectorFamily({
    key: 'questions/Default',
    get:
      setId =>
      async ({ get }) => {
        const { token } = get(auth)
        const data = await client<QuestionObject[]>(`questions/set/${setId}/questions`, { token })
        return data
      },
  }),
})

export const useQuestionSetQuestions = (setId: string) => {
  const loadable = useRecoilValueLoadable(questionsDetails(setId))
  return {
    data: loadable.state === 'hasValue' ? loadable.contents : undefined,
    error: loadable.state === 'hasError' ? loadable.contents : undefined,
    loading: loadable.state === 'loading',
  }
}

export const useUpdateQuestion = () =>
  useRecoilCallback<[setId: string, update: Partial<QuestionObject>], Promise<void>>(
    ({ reset, set, snapshot }) =>
      async (setId, update) => {
        const { token } = await snapshot.getPromise(auth)

        const createUpdate = (currQuestions: QuestionObject[]) => {
          const prevQuestion = currQuestions.find(q => q._id === update._id)
          return currQuestions.map(q =>
            q._id === update._id ? deepMerge(prevQuestion, update) : q,
          )
        }

        set(questionsDetails(setId), currQuestions => {
          return createUpdate(currQuestions)
        })

        try {
          await client<void>(`questions/set/${setId}/question`, {
            method: Method.PATCH,
            data: {
              update: {
                ...update,
                _id: update._id,
              },
            },
            token,
          })

          toast.success('Question updated')
        } catch (error) {
          reset(questionsDetails(setId))
          toast.error('Failed to update question.')
          return Promise.reject(error)
        }
      },
    [],
  )

export const useAddQuestion = () =>
  useRecoilCallback<[setId: string, question?: Partial<QuestionObject>], Promise<QuestionObject>>(
    ({ reset, set, snapshot }) =>
      async (setId, question) => {
        const { token } = await snapshot.getPromise(auth)
        try {
          const data = await client<QuestionObject>(`questions/set/${setId}/question`, {
            method: Method.POST,
            data: {
              question: question || {},
            },
            token,
          })

          set(questionsDetails(setId), currQuestions => [...currQuestions, data])
          toast.success('Question added')

          return data
        } catch (error) {
          reset(questionsDetails(setId))
          toast.error('Failed to add question.')
          return Promise.reject(error)
        }
      },
    [],
  )

export const useDeleteQuestion = () =>
  useRecoilCallback<[setId: string, questionId: string], Promise<void>>(
    ({ reset, set, snapshot }) =>
      async (setId, questionId) => {
        const { token } = await snapshot.getPromise(auth)
        try {
          await client<void>(`questions/set/${setId}/question/${questionId}`, {
            method: Method.DELETE,
            token,
          })

          set(questionsDetails(setId), currQuestions =>
            currQuestions.filter(q => q._id !== questionId),
          )

          toast.success('Question deleted')
        } catch (error) {
          reset(questionsDetails(setId))
          console.log(error)
          toast.error('Failed to delete question.')
          return Promise.reject(error)
        }
      },
    [],
  )
