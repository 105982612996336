//https://github.com/chatscope/use-chat/blob/main/src/examples/ExampleChatService.ts

import { useCallback, useEffect, useRef } from 'react'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import io, { Socket } from 'socket.io-client'

import { GameObject, useGameInfo, useGetGameInfo } from '../apps/quizio/state/gameState'
import { useSetQuestion } from '../apps/quizio/state/questionState'
import { useAuth } from '../state'

import { Settings } from '.'

const GAME_COOKIE_LIFETIME = new Date(new Date().valueOf() + 60 * 60 * 1000)

let socket: Socket

export const useInitiateSocket = () => {
  const initDone = useRef(false)
  const { token } = useAuth()
  const [, setGameInfo] = useGameInfo()
  const setQuestion = useSetQuestion()
  const [cookies] = useCookies(['activeGame'])

  useEffect(() => {
    if (initDone.current) return
    socket = io(`${Settings.BASE_WS_PATH}`, {
      path: '/quizio',
      transports: ['websocket'],
      auth: {
        token,
      },
    })

    if (cookies.activeGame) {
      socket.emit('rejoinGame', { gameId: cookies.activeGame })
    }

    socket.on('updateGameObject', res => {
      setGameInfo(gameObject => ({ ...gameObject, ...res }))
    })

    socket.on('updateQuestionObject', res => {
      setQuestion(questionsObject => ({ ...questionsObject, ...res }))
    })

    initDone.current = true
  }, [cookies.activeGame, setGameInfo, setQuestion, token])
}

export const disconnectSocket = () => {
  console.log('Disconnecting socket...')
  if (socket) socket.disconnect()
}

export const useJoinGame = () => {
  const [, setGameInfo] = useGameInfo()
  const [, setCookie] = useCookies(['activeGame'])
  return ({ gameId, playerId }: { gameId: string; playerId: string }) => {
    if (!socket || !gameId) return false
    socket.emit('joinGame', { gameId, playerId }, response => {
      setGameInfo(response)
      setCookie('activeGame', response.gameId, {
        path: '/',
        expires: GAME_COOKIE_LIFETIME,
      })
    })
  }
}

export const useCreateLobby = () => {
  const [, setGameInfo] = useGameInfo()
  const [, setCookie] = useCookies(['activeGame'])
  const defaultOptions = { gameType: 'auto', public: true }

  return (options?: Partial<GameObject>) => {
    if (!socket) return
    socket.emit('createQuiz', { ...defaultOptions, ...options }, response => {
      setGameInfo(response)
      setCookie('activeGame', response.gameId, {
        path: '/',
        expires: GAME_COOKIE_LIFETIME,
      })
    })
  }
}

export const useLeaveGame = () => {
  const [game, setGameInfo] = useGameInfo()
  const [cookies, , removeCookie] = useCookies(['activeGame'])

  return () => {
    if (!socket) return false
    console.log('leaving...')
    socket.emit('leaveGame', { gameId: cookies.activeGame }, state => {
      if (state) {
        removeCookie('activeGame')
        setGameInfo({ ...game, gameState: -1 })
      }
    })
  }
}

export const useSetQuestionSet = () => {
  const { gameId } = useGetGameInfo()

  return (setId: string) => {
    if (!socket) return false
    socket.emit('setQuestionSet', { gameId, setId }, ack => {
      if (!ack) console.log('Could not set question set')
    })
  }
}

export const useStartGame = () => {
  const [{ gameId }] = useGameInfo()

  return () => {
    if (!socket) return false
    socket.emit('startGame', { gameId }, ack => {
      if (!ack) console.log('Could not start game')
    })
  }
}

// export const useGetQuestion = () => {
//   const [gameObject] = useGameInfo()
//   const setQuestion = useSetQuestion()

//   return useCallback(() => {
//     if (gameObject.gameState === GameState.QUESTION) {
//       socket.emit('getQuestion', { gameId: gameObject.gameId }, setQuestion)
//     }
//   }, [gameObject.gameId, gameObject.gameState, setQuestion])
// }

export const useAnswerQuestion = () => {
  const [{ gameId }] = useGameInfo()
  return useCallback(
    (answer: number | string | string[]) => {
      socket.emit('answerQuestion', { gameId, answer }, ack => {
        if (!ack) toast.error('Something went wrong with the answer, buddy!')
        return
      })
    },
    [gameId],
  )
}
