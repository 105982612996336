import { Icon } from '@iconify/react'
import { Box, Text } from 'grommet'
import React from 'react'

interface IAuthError {
  status: number
  message?: string | string[]
}

const AuthError = ({ error }: { error: IAuthError }) => {
  const errorMsg = () => {
    switch (error.status) {
      // Custom errors
      case 1000:
        return 'Something is missing... Fill all the inputs'
      case 1001:
        return 'Register not allowed.'
      case 1002:
        return (
          <Box gap="small">
            {(error.message as string[]).map((msg, i) => (
              <Box direction="row" gap="small" align="top">
                {error.message?.length > 1 && (
                  <Box flex={false} margin={{ top: '2px' }}>
                    <Icon icon="tabler:point-filled" />
                  </Box>
                )}
                <Text size="xxsmall" key={i}>
                  {msg.charAt(0).toUpperCase() + msg.slice(1)}
                </Text>
              </Box>
            ))}
          </Box>
        )
      // HTTP errors
      case 400:
      case 401:
        return 'Wrong username or password'
      case 406:
        return error.message
      // Server errors
      case 500:
        return 'Something is not right with the server... Try later.'
      // Default
      default:
        return 'Something is not right at all... Come back later.'
    }
  }

  return <div>{errorMsg()}</div>
}

export default AuthError
