import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Grommet, ThemeType } from 'grommet'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { ToastContainer } from 'react-toastify'
import { RecoilRoot } from 'recoil'

import { LoadingSpinner } from './components'
import { RootRouter } from './router'
import Bootstrap from './router/util/Bootstrap'
import { mainTheme } from './style'

import 'react-toastify/dist/ReactToastify.css'
import './style/index.scss'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: process.env.NODE_ENV !== 'development' ? 0 : 1000 * 60 * 60 * 1,
    },
  },
})

const App = () => {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Grommet theme={mainTheme as ThemeType} full>
          <ToastContainer />
          <Suspense fallback={<LoadingSpinner />}>
            <Bootstrap>
              <RootRouter />
            </Bootstrap>
          </Suspense>
        </Grommet>
      </QueryClientProvider>
    </RecoilRoot>
  )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
