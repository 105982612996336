import { Icon } from '@iconify/react'
import { Heading } from 'grommet'
import React from 'react'

import Card from '../../../components/Card'
import { useUser } from '../../../state'
import { fonts } from '../../../style'
import { Button } from '../../../ui'
import { useJoinGame } from '../../../util/socket'
import { useGetAvailableGameList } from '../util/questions'

import GameListEntry from './GameListEntry'

const QuizGameList = () => {
  const user = useUser()
  const joinGame = useJoinGame()
  const { isLoading, data, refetch } = useGetAvailableGameList()

  const handleJoin = (gameId: string) => joinGame({ gameId, playerId: user._id })

  return (
    <Card>
      <Heading margin={{ top: 'none', bottom: 'small' }} level={4}>
        AVAILABLE GAMES
      </Heading>
      {!isLoading
        ? data.map((game, i) => <GameListEntry key={i} onJoin={handleJoin} {...game} />)
        : null}
      <Button
        label="Refresh"
        icon={<Icon icon="icon-park-solid:reload" fontSize={fonts.body1.fontSize * 1.25} />}
        onClick={() => refetch()}
      />
    </Card>
  )
}

export default QuizGameList
