import { Box } from 'grommet'
import React from 'react'

import Card from '../../../components/Card'
import { Button } from '../../../ui'
import { useLeaveGame } from '../../../util/socket'
import PlayerList from '../components/PlayerList'
import { GameState, useGameInfo } from '../state/gameState'

import WatingForAnswers from './WatingForAnswers'

const Answers = () => {
  const [gameObject] = useGameInfo()
  const leaveGame = useLeaveGame()

  return (
    <Box as="section" align="center" flex="grow">
      <Button label="Leave game" alignSelf="end" hoverColor="accent-1" onClick={leaveGame} />
      {gameObject.gameState === GameState.ANSWERED ? (
        <Card>
          <p>Question answered... Waiting for others to answer</p>
        </Card>
      ) : (
        <WatingForAnswers />
      )}
      <PlayerList />
    </Box>
  )
}

export default Answers
