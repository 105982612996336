import { Icon } from '@iconify/react'
import { Box, Text } from 'grommet'
import { isEqual, pickBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, LoadingSpinner } from '../../../components'
import { Button, Input } from '../../../ui'
import TextArea from '../../../ui/TextArea'
import { useAsync } from '../../../util'
import {
  useAddQuestion,
  useGetUserQuestionSetById,
  useModifyQuestionSet,
  useQuestionSetQuestions,
} from '../util/questions'

function EditQuestionSet() {
  const { run: runSave, isLoading: isSaving } = useAsync()
  const { state } = useLocation()
  const navigate = useNavigate()

  const { data: set, loading: setLoading } = useGetUserQuestionSetById(state.id)
  const { data: questions, loading: quesionsLoading } = useQuestionSetQuestions(state.id)

  const modifyQuestionSet = useModifyQuestionSet()
  const addQuestion = useAddQuestion()

  const [localQuestionSet, setLocalQuestionSet] = useState(undefined)

  useEffect(() => {
    if (set && !setLoading) setLocalQuestionSet(set)
  }, [set, setLoading])

  const handleSave = async () =>
    await runSave(
      modifyQuestionSet(
        set?._id,
        pickBy(localQuestionSet, (v, k) => !isEqual(set[k], v)),
      ),
    )

  const handleAddQuestion = async () => {
    const newQuestion = await addQuestion(state.id)
    navigate(`/apps/quizio/edit-question`, { state: { id: newQuestion._id, setId: state.id } })
  }

  const onEditQuestion = (id: string) =>
    navigate(`/apps/quizio/edit-question`, { state: { id, setId: state.id } })

  return localQuestionSet ? (
    <Box as="section" align="center" flex="grow">
      <Button label="GO BACK" alignSelf="end" hoverColor="accent-1" onClick={() => navigate(-1)} />
      <Card>
        <Text size="large" margin={{ bottom: 'medium' }}>
          Edit Question Set
        </Text>
        <Input
          label="Question Set Name"
          value={localQuestionSet.name}
          onChange={e => setLocalQuestionSet({ ...localQuestionSet, name: e.target.value })}
          margin={{ bottom: 'medium' }}
        />
        <TextArea
          label="Question Set Description"
          value={localQuestionSet.desc}
          onChange={e => setLocalQuestionSet({ ...localQuestionSet, desc: e.target.value })}
        />
        <Box margin={{ top: 'medium' }}>
          {!quesionsLoading
            ? questions?.map((q, i) => {
                return (
                  <Box
                    key={q._id}
                    direction="row"
                    align="center"
                    justify="between"
                    margin={{ bottom: 'small' }}
                  >
                    <Box direction="row" align="center">
                      <Box
                        background="accent-1"
                        width="32px"
                        height="32px"
                        margin={{ right: 'small' }}
                        align="center"
                        justify="center"
                        round="full"
                        elevation="small"
                        flex={false}
                      >
                        <Text size="xsmall">{i + 1}</Text>
                      </Box>
                      <Text size="xsmall" truncate>
                        {q.data.questionText}
                      </Text>
                    </Box>
                    <Button
                      icon={<Icon icon="bxs:edit" fontSize={18} />}
                      tip={{ content: <Text size="xsmall">Edit Question</Text> }}
                      onClick={() => onEditQuestion(q._id)}
                    />
                  </Box>
                )
              })
            : null}
          <Box direction="row" align="center" justify="end">
            <Button
              icon={<Icon icon="mdi:add-bold" fontSize={18} />}
              tip={{ content: <Text size="xsmall">Add Question</Text> }}
              onClick={() => handleAddQuestion()}
            />
          </Box>
        </Box>

        <Button
          label={isSaving ? 'SAVING...' : 'SAVE SET'}
          alignSelf="end"
          onClick={() => handleSave()}
          margin={{ top: 'medium' }}
        />
      </Card>
    </Box>
  ) : (
    <LoadingSpinner />
  )
}

export default EditQuestionSet
