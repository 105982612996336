import { Text, Page, Footer, PageContent } from 'grommet'
import React from 'react'
import { Outlet } from 'react-router-dom'

import buildNumber from '../buildnumber.json'
import { Navigation } from '../navigation'

export const Layout = () => {
  return (
    <Page kind="narrow" fill role="presentation">
      <Navigation />
      <PageContent as="main" fill style={{ overflowY: 'auto' }} role="presentation">
        <Outlet />
      </PageContent>
      <Footer pad="xsmall" background="dark-2" justify="center">
        <Text size="xsmall" color="brand" textAlign="center">
          LUSORIUM v{buildNumber.major}.{buildNumber.minor}.{buildNumber.patch} BUILD:{' '}
          {buildNumber.build} | (c) breezertwo
        </Text>
      </Footer>
    </Page>
  )
}
