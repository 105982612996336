import { Box, TextInput, Text, TextInputProps as BaseInputProps } from 'grommet'
import { MarginType } from 'grommet/utils'
import React, { useState } from 'react'
import styled from 'styled-components'

import { mainTheme } from '../style'

export interface InputProps extends BaseInputProps {
  label: string
  labelBackground?: string
  margin?: MarginType
}

const StyledText = styled(Text)`
  position: absolute;
  left: 8px;
  top: 10px;
  padding: 4px;
  background-color: ${props => props.labelBackground};
  transition: all 0.5s cubic-bezier(0.33, 1, 0.68, 1) 0s;
  pointer-events: none;
  ${props =>
    props.size === '10px'
      ? `
      transform: translate(2px, -17px);
      color: ${
        !props.disabled ? mainTheme.global.colors['brand'] : mainTheme.global.colors['grey']
      }; };
      `
      : ''}
`

const Input = ({
  label,
  labelBackground = mainTheme.global.colors['light-1'],
  onChange,
  onFocus,
  onBlur,
  margin,
  value,
  disabled,
  ...props
}: InputProps) => {
  const [focused, setFocused] = useState(false)
  const [hasVal, setHasValue] = useState(value !== '' && value !== undefined)

  const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true)
    onFocus?.(e)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(false)
    onBlur?.(e)
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasValue(e.target.value !== '' && e.target.value !== undefined)
    onChange?.(e)
  }

  return (
    <Box style={{ position: 'relative' }} margin={margin}>
      <TextInput
        size="small"
        aria-label={label}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleOnChange}
        value={value}
        disabled={disabled}
        {...props}
      />
      <StyledText
        size={focused || hasVal ? '10px' : 'small'}
        labelBackground={labelBackground}
        disabled={disabled}
      >
        {label}
      </StyledText>
    </Box>
  )
}
export default Input
