import { isNil, isUndefined, omitBy } from 'lodash'

import { Settings } from '.'

export enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface ClientConfig<D = unknown> extends RequestInit {
  data?: D
  token?: string | null
  method?: Method
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const client = async <R = any, D = unknown>(
  endpoint: string,
  { method, data, token }: ClientConfig<D> = {
    method: Method.GET,
  },
): Promise<R> => {
  return fetch(`${Settings.BASE_API_PATH}/${endpoint}`, {
    method,
    body: data ? JSON.stringify(omitBy(data, isUndefined)) : undefined,
    headers: omitBy(
      {
        Accept: 'application/json',
        'MS-Client-Origin': 'mindstream-webapp',
        'Content-Type': (data ? 'application/json' : undefined) as string,
        Authorization: (token ? `Bearer ${token}` : undefined) as string,
      },
      isNil,
    ),
  }).then(async res => {
    const body = await res.json()
    if (!res.ok) {
      throw { status: res.status, ...body }
    }
    return body
  })
}

export default client
