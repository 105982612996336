import { Icon } from '@iconify/react'
import { Text, Box, Heading } from 'grommet'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import placeholderImg from '../../../assets/placholderImg.png'
import { RoleMap } from '../../../interfaces'
import { usePermissionLevel } from '../../../state'
import Card from '../../Card'

const games = [
  {
    id: 'quizio',
    title: 'quiz.io',
    permissionLevel: RoleMap.DEFAULT,
    imageUrl: placeholderImg,
  },
  {
    id: 'unkown',
    title: 'empty game slot',
    permissionLevel: 999,
    imageUrl: null,
  },
  {
    id: 'unkown',
    title: 'empty game slot',
    permissionLevel: 999,
    imageUrl: null,
  },
]

const GameList = () => {
  const navigate = useNavigate()
  const permissionLevel = usePermissionLevel()

  return (
    <Card direction="row" ariaDescribedby="Box with available games">
      <Heading margin={{ top: 'none', bottom: 'small' }} level={3}>
        AVAILABLE GAMES
      </Heading>
      <Box fill>
        {games.map((game, index) => {
          const available = permissionLevel >= game.permissionLevel
          return (
            <Box
              role="button"
              aria-disabled={!available}
              key={index}
              background="accent-4"
              pad="small"
              margin={{ vertical: 'xsmall' }}
              round="xxsmall"
              hoverIndicator={available ? 'focus' : undefined}
              justify="start"
              align="center"
              direction="row"
              gap="small"
              elevation="xsmall"
              border={false}
              onClick={() => available && navigate(`/apps/${game.id}`)}
            >
              {game.id === 'quizio' ? <Icon icon="mdi:crosshairs-question" fontSize={24} /> : null}
              <Text size="medium">{game.title}</Text>
            </Box>
          )
        })}
      </Box>
    </Card>
  )
}

export default GameList
