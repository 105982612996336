import { Box } from 'grommet'
import { FillType } from 'grommet/utils'
import React from 'react'
import { RingLoader } from 'react-spinners'

import { mainTheme } from '../style'

interface LoadingSpinnerProps {
  fill?: FillType
}

const LoadingSpinner = ({ fill = true }: LoadingSpinnerProps) => {
  return (
    <Box align="center" justify="center" fill={fill}>
      <RingLoader size={80} speedMultiplier={0.5} color={mainTheme.global.colors['light-1']} />
    </Box>
  )
}

export default LoadingSpinner
