import { atom, DefaultValue, selector, useRecoilCallback, useRecoilValue } from 'recoil'

import client, { Method } from '../util/client'

import { isLoggedIn, userAtom } from './user'

export interface Auth {
  token: string
  expires: number
}

interface AuthResponse {
  userId: string
  token: string
  refreshToken: string
}

export interface RefreshResponse {
  accessToken: string
}

interface LoginCredentials {
  username: string
  password: string
}

interface RegisterCredentials {
  email: string
  username: string
  password: string
}

const getRefreshToken = (): string => localStorage.getItem('REFRESH_TOKEN')
const setRefreshToken = (token: string): void => localStorage.setItem('REFRESH_TOKEN', token)
const removeRefreshToken = (): void => localStorage.removeItem('REFRESH_TOKEN')

const token = atom<Auth>({
  key: 'AuthToken',
  default: null,
})

export const auth = selector<Auth>({
  key: 'Auth',
  get: ({ get }) => ({
    ...get(token),
  }),
  set: ({ reset, set }, value) => {
    if (value instanceof DefaultValue) {
      reset(token)
      return
    }

    set(token, value)
  },
})

export const useAuth = () => useRecoilValue(auth)

export const useRestoreAuth = () =>
  useRecoilCallback(
    ({ set }) =>
      async () => {
        const refreshToken = getRefreshToken()
        if (refreshToken) {
          try {
            const { accessToken } = await client<RefreshResponse>('auth/refresh', {
              method: Method.POST,
              data: {
                refreshToken,
              },
            })

            set(auth, {
              token: accessToken,
              expires: Math.round(Date.now() / 1000) + 5 * 60,
            })

            return { token: accessToken }
          } catch (error) {
            removeRefreshToken()
            console.log('Refresh token expired')
          }
        }
        return { token: null }
      },
    [],
  )

export const useLogin = () =>
  useRecoilCallback(
    ({ set }) =>
      async (credentials: LoginCredentials) => {
        const { token, refreshToken } = await client<AuthResponse>('auth/login', {
          method: Method.POST,
          data: credentials,
        })

        setRefreshToken(refreshToken)

        const data = {
          token: token,
          expires: Math.round((Date.now() + 5 * 60) / 1000),
        }

        set(auth, data)

        return data
      },
    [],
  )

export const useRegister = () =>
  useRecoilCallback(
    ({ set }) =>
      async (credentials: RegisterCredentials) => {
        const { token, refreshToken } = await client<AuthResponse>('auth/register', {
          method: Method.POST,
          data: credentials,
        })

        setRefreshToken(refreshToken)

        const data = {
          token: token,
          expires: Date.now() + 300 * 1000,
        }

        set(auth, data)
        return data
      },
    [],
  )

export const useLogout = () =>
  useRecoilCallback(
    ({ reset, snapshot }) =>
      async () => {
        const { token } = await snapshot.getPromise(auth)
        await client<void>('auth/logout', {
          token,
          method: Method.DELETE,
        })

        reset(auth)
        return removeRefreshToken()
      },
    [],
  )

export const useReset = () =>
  useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(isLoggedIn)
        reset(userAtom)
      },
    [],
  )
