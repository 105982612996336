import React, { useEffect } from 'react'

import { LoadingSpinner } from '../../components'
import { useLogout, useRestoreAuth } from '../../state'
import { AsyncStatus, useAsync, useBootstrap, useInterval } from '../../util'

interface BootstrapProps {
  children: React.ReactNode
}

const Bootstrap = ({ children }: BootstrapProps) => {
  const restoreAuth = useRestoreAuth()
  const bootstrap = useBootstrap()
  const logout = useLogout()
  const { run, status } = useAsync()

  useInterval(() => {
    console.log('Refreshing auth token')
    restoreAuth()
  }, 1000 * 60 * 4.9 /* 4.9 minutes */)

  useEffect(() => {
    run(restoreAuth().then(bootstrap).catch(logout))
  }, [run, restoreAuth, bootstrap, logout])

  if (status !== AsyncStatus.Success) return <LoadingSpinner />

  return <>{children}</>
}

export default Bootstrap
