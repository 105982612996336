import { Box, Heading, ResponsiveContext, Text } from 'grommet'
import React from 'react'
import styled from 'styled-components'

const classToLevel = {
  small: 3,
  medium: 2,
  large: 2,
}

const StyledHeading = styled(Heading)`
  color: #f8e9c0;
  border: 0.2em solid;
  padding: 1em 1.75em;
  text-shadow: 0.1em 0.1em 0.1em #00000047;
  background: #0000008c;
  letter-spacing: 0.1875em;
`

const Home = (): JSX.Element => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box justify="center" fill>
          <StyledHeading textAlign="center" level={classToLevel[size]} fill>
            LUSORIUM.IO
          </StyledHeading>
          <Box margin={{ vertical: 'medium' }} pad="small" background="white">
            <Text size={size === 'small' ? 'small' : 'medium'} color="dark-2" textAlign="center">
              A platform for creating and playing all kinds of minigames.
            </Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Home
