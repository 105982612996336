import { Box, Text } from 'grommet'
import React, { useState } from 'react'

import { Button, DraggableList } from '../../../../ui'
import { useAnswerQuestion } from '../../../../util/socket'
import { SortQuestionType } from '../../state/questionState'

interface SortQuestionProps {
  data: SortQuestionType
}

const SortQuestion = ({ data }: SortQuestionProps) => {
  const [answers, setAnswers] = useState(data.answers)

  const sendAnswer = useAnswerQuestion()

  const answerQuestion = () => {
    sendAnswer(answers)
  }

  return (
    <>
      <Box pad={'medium'} round="xsmall" background={'#E6C8A9'}>
        <Text>{data.questionText}</Text>
      </Box>
      <DraggableList items={data.answers} onChange={setAnswers} margin={{ vertical: 'small' }} />
      <Button label="Answer Question" onClick={answerQuestion} />
    </>
  )
}

export default SortQuestion
