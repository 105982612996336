import { Icon } from '@iconify/react'
import { useHover } from '@use-gesture/react'
import { Text, Box } from 'grommet'
import React, { useState } from 'react'

import { fonts, mainTheme } from '../../../style'

interface GameListEntryProps {
  gameId: string
  players: number
  onJoin: (gameId: string) => void
}

const GameListEntry = ({ gameId, players, onJoin }: GameListEntryProps) => {
  const [hovering, setIsHovering] = useState(false)

  const bind = useHover(hover => {
    setIsHovering(hover.active)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any

  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      round="xsmall"
      margin={{ vertical: 'xsmall' }}
      pad="xsmall"
      border={{ color: 'black', size: 'small' }}
    >
      <Text size="small">{gameId}</Text>
      <Box direction="row" align="center" gap="xxsmall">
        <Text size="small">{players}</Text>
        <Icon icon="ic:round-people-alt" fontSize={fonts.body1.fontSize * 1.25} />
      </Box>
      <Box
        aria-label={`join game ${gameId}`}
        focusable
        direction="row"
        align="center"
        gap="xxsmall"
        onClick={() => onJoin(gameId)}
        style={{
          cursor: 'pointer',
          color: !hovering ? mainTheme.global.colors['accent-1'] : mainTheme.global.colors['focus'],
        }}
        {...bind()}
      >
        <Icon icon="ri:login-circle-fill" fontSize={fonts.body1.fontSize * 1.25} />
        <Text size="small">join</Text>
      </Box>
    </Box>
  )
}

export default GameListEntry
