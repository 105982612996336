import { useCallback, useState } from 'react'

export enum AsyncStatus {
  Idle,
  Pending,
  Success,
  Error,
}

interface State<T = unknown, E = unknown> {
  status: AsyncStatus
  value?: T | null
  error?: E | null
}

// Hook
export const useAsync = <T = unknown, E = unknown>() => {
  const [{ status, error, value }, setState] = useState<State<T, E>>({
    status: AsyncStatus.Idle,
    value: null,
    error: null,
  })

  const run = useCallback((promise: Promise<T>): Promise<void> => {
    setState(state => ({
      ...state,
      status: AsyncStatus.Pending,
    }))

    return promise
      .then(response => {
        setState(state => ({
          ...state,
          value: response,
          status: AsyncStatus.Success,
        }))
      })
      .catch(error => {
        setState(state => ({
          ...state,
          error,
          status: AsyncStatus.Error,
        }))
      })
  }, [])

  return { run, status, value, error, isLoading: status === AsyncStatus.Pending }
}
