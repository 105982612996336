import { ThemeType } from 'grommet'
import { deepFreeze } from 'grommet/utils'

interface CustomThemeType {
  text: {
    xxsmall?: {
      size: string
      height: string
    }
  }
}

type CustomTheme = ThemeType & CustomThemeType

export const mainTheme = deepFreeze<CustomTheme>({
  global: {
    // breakpoints: {
    //   small: {
    //     value: 600,
    //   },
    //   medium: {
    //     value: 900,
    //   },
    //   large: {
    //     value: 3000,
    //   },
    // },
    colors: {
      brand: '#F8874A',
      focus: '#90A959',
      selected: '#4c3130',
      'accent-1': '#A63D40',
      'accent-2': '#848FA5',
      'accent-3': '#9AB168',
      'accent-4': '#ecc570',
      'dark-1': '#2D2D2A',
      'dark-2': '#031323',
      'light-1': '#F9F4F0',
      gold: '	#FFD700',
      warning: '#D0342C',
      grey: '#a5a5a5',
      text: {
        dark: '#F9F4F0',
        light: 'black',
      },
    },
    elevation: {
      light: {
        large: '0.125em 0.125em 0.25em #031323',
        xlarge: '0.3rem 0.3rem 0 #333',
      },
    },
    font: {
      family: `'Press Start 2P'`,
    },
    control: {
      border: {
        width: '3px',
        radius: '6px',
        color: 'dark-2',
      },
    },
    focus: {
      border: {
        color: 'brand',
      },
    },
  },
  button: {
    default: {
      color: {
        dark: 'black',
      },
      background: {
        color: 'light-1',
      },
      extend: props => {
        return {
          backgroundColor: props.colorValue,
          border: '2px solid #031323',
          borderRadius: '6px',
          boxShadow: '0.125em 0.125em 0.25em #031323',
          textTransform: 'uppercase',
          transformStyle: 'preserve-3d',
        }
      },
    },
    transition: {
      duration: 0.15,
      properties: ['transform'],
      timing: 'cubic-bezier(0, 0, 0.58, 1)',
    },
    disabled: {
      background: {
        color: 'black',
      },
    },
    hover: {
      extend: () => {
        return {
          transform: 'translate(0, 0.175em)',
        }
      },
    },
  },
  tip: {
    content: {
      background: 'accent-2',
      elevation: 'small',
      round: 'small',
    },
    drop: {
      align: { top: 'bottom', right: 'left' },
    },
  },
  text: {
    xxsmall: {
      size: '10px',
      height: '14px',
    },
    xsmall: {
      size: '12px',
      height: '22px',
    },
    small: {
      size: '14px',
      height: '24px',
    },
    medium: {
      size: '18px',
      height: '24px',
    },
    large: {
      size: '22px',
      height: '28px',
    },
    xlarge: {
      size: '26px',
      height: '32px',
    },
    xxlarge: {
      size: '34px',
      height: '40px',
    },
  },
})
