import { Icon } from '@iconify/react'
import { Box } from 'grommet'
import React from 'react'

import Input, { InputProps } from './Input'

interface InputEditProps extends InputProps {}

const InputEdit = ({ ...props }: InputEditProps) => {
  const [disabled, setDisabled] = React.useState(true)

  return (
    <Box direction="row" flex="grow" margin={{ bottom: 'medium' }}>
      <Input {...props} disabled={disabled} />
      <Box
        width="47px"
        height="47px"
        justify="center"
        align="center"
        border={{ color: 'black', size: 'medium' }}
        round="xsmall"
        onClick={() => setDisabled(!disabled)}
        hoverIndicator="dark-2"
      >
        <Icon icon={disabled ? 'ic:sharp-edit' : 'ic:round-save'} fontSize={28} />
      </Box>
    </Box>
  )
}
export default InputEdit
