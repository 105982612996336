import { useRecoilCallback } from 'recoil'

import { isLoggedIn, userAtom, Auth } from '../state'

import client from './client'

export const useBootstrap = () =>
  useRecoilCallback(
    ({ set }) =>
      async ({ token }: Auth) => {
        if (!token) {
          console.log('No session')
          return
        }

        const user = await client('user', { token })
        set(userAtom, user)

        set(isLoggedIn, true)
      },
    [],
  )
