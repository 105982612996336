import { atom, selector, useRecoilValue } from 'recoil'

import { RoleMap, User } from '../interfaces'

const userAtom = atom<User>({
  key: 'user',
  default: null,
})

export const useUser = () => useRecoilValue(userAtom)

export const isLoggedIn = atom<boolean>({
  key: 'isLoggedIn',
  default: false,
})
export const useLoggedIn = () => useRecoilValue(isLoggedIn)

const userPermissionLevel = selector<number>({
  key: 'userPermissionLevel',
  get: ({ get }) => {
    const user = get(userAtom)
    if (!user) return -1

    return user.roles.reduce((level, role) => {
      if (RoleMap[role] === undefined) return level
      if (level > RoleMap[role]) return level
      return RoleMap[role]
    }, 0)
  },
})

export const usePermissionLevel = () => useRecoilValue(userPermissionLevel)

export { userAtom }
