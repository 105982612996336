import { Icon } from '@iconify/react'
import { Box, Text } from 'grommet'
import { deepMerge } from 'grommet/utils'
import { isEmpty, isEqual, pickBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Card, LoadingSpinner } from '../../../components'
import { Button, Dropdown, Input, TextArea } from '../../../ui'
import { useSize } from '../../../ui/useSize'
import { useAsync } from '../../../util'
import { QuestionObject, QuestionType, StandartQuestionType } from '../state/questionState'
import { useDeleteQuestion, useQuestionSetQuestions, useUpdateQuestion } from '../util/questions'

import NewAnswer from './NewAnswer'

const EditQuestion = () => {
  const [localQuestion, setLocalQuestion] = useState<QuestionObject>(undefined)

  const size = useSize()
  const { run, isLoading: isSaving } = useAsync()
  const { state } = useLocation()
  const navigate = useNavigate()

  const { data: questions, loading: quesionsLoading } = useQuestionSetQuestions(state.setId)
  const updateQuestion = useUpdateQuestion()
  const deleteQuestion = useDeleteQuestion()

  useEffect(() => {
    if (questions && !quesionsLoading) {
      console.log('useEffect')
      setLocalQuestion(questions.find(q => q._id === state.id))
      setAnswers(
        (questions.find(q => q._id === state.id).data as StandartQuestionType).answers || [],
      )
    }
  }, [quesionsLoading, questions, state.id])

  const handleSave = async () => {
    const oldQuestion = questions.find(q => q._id === state.id)
    const update = pickBy(localQuestion, (v, k) => !isEqual(oldQuestion[k], v))

    if (isEmpty(update)) return

    await run(
      updateQuestion(state.setId, {
        ...update,
        _id: state.id,
      }),
    )
  }

  const handleDelete = async () => {
    navigate(-1)
    await run(deleteQuestion(state.setId, state.id))
  }

  // Question Types
  const questionTypes = [
    { _id: '0', name: 'Standart', value: QuestionType.STANDART },
    { _id: '1', name: 'Guess', value: QuestionType.GUESS },
    { _id: '2', name: 'Text', value: QuestionType.TEXT },
    { _id: '3', name: 'Sort', value: QuestionType.SORT },
  ]

  // Simple Add Answer for Standart Question funconaity
  const [answers, setAnswers] = useState(
    (localQuestion?.data as StandartQuestionType).answers || [],
  )
  const [currentAnswer, setCurrentAnswer] = useState('')

  const onAddAnswer = () => {
    if (answers.length >= 4) return toast.error('You can only have 4 answers')
    const update = [...answers, currentAnswer]
    setAnswers(update)
    setLocalQuestion(deepMerge(localQuestion, { data: { answers: update } }))
  }

  const onRemoveAnswer = (index: number) => {
    const update = answers.filter((_, i) => i !== index)
    setAnswers(update)
    setLocalQuestion(deepMerge(localQuestion, { data: { answers: update } }))
  }

  const updateAnswer = (index: number, value: string) => {
    const update = answers.map((a, i) => (i === index ? value : a))
    setAnswers(update)
    setLocalQuestion(deepMerge(localQuestion, { data: { answers: update } }))
  }

  return localQuestion ? (
    <Box as="section" align="center" flex="grow">
      <Button label="GO BACK" alignSelf="end" hoverColor="accent-1" onClick={() => navigate(-1)} />
      <Card>
        <Text size="large" margin={{ bottom: 'medium' }}>
          Edit Question
        </Text>
        <TextArea
          label="Question Text"
          size="small"
          value={localQuestion.data.questionText}
          onChange={e => {
            setLocalQuestion(localQuestion =>
              deepMerge(localQuestion, { data: { questionText: e.target.value } }),
            )
          }}
        />
        <Box direction="row" justify="between" align="start" margin={{ top: 'small' }}>
          <Box margin={{ top: '26px' }}>
            <Text size="small">Type</Text>
          </Box>
          <Box width={{ min: '200px', max: size === 'small' ? '354px' : '368px' }} flex>
            <Dropdown
              selected={localQuestion.questionType?.toString() || '0'}
              data={questionTypes}
              onChange={_id => {
                setLocalQuestion(localQuestion =>
                  deepMerge(localQuestion, { questionType: parseInt(_id) }),
                )
              }}
            />
          </Box>
        </Box>

        {localQuestion.questionType === QuestionType.STANDART && (
          <>
            <Box direction="row" align="center" margin={{ top: 'small' }} justify="between">
              <Text size="small">Add answer</Text>
              <Box width={{ max: '400px' }} direction="row" align="center">
                <Input
                  label="Answer"
                  size="small"
                  value={currentAnswer}
                  onChange={e => setCurrentAnswer(e.target.value)}
                />
                <Box
                  margin={{ left: 'small' }}
                  background={{ color: 'accent-3' }}
                  pad="xsmall"
                  round="full"
                  hoverIndicator={{ background: 'accent-2' }}
                  onClick={onAddAnswer}
                  flex={false}
                >
                  <Icon icon="mdi:add-bold" fontSize={24} />
                </Box>
              </Box>
            </Box>
            <Box direction="column" margin={{ top: 'small' }}>
              {answers.map((answer, index) => (
                <NewAnswer
                  key={index}
                  index={index}
                  answer={answer}
                  updateAnswer={updateAnswer}
                  onRemoveAnswer={onRemoveAnswer}
                />
              ))}
            </Box>
          </>
        )}
        <Box direction="row" justify="end" margin={{ top: 'medium' }} gap="medium">
          <Button
            label={'Deleted'}
            alignSelf="end"
            hoverColor={'accent-1'}
            onClick={() => handleDelete()}
            margin={{ top: 'medium' }}
          />
          <Button
            label={isSaving ? 'SAVING...' : 'SAVE'}
            alignSelf="end"
            onClick={() => handleSave()}
            margin={{ top: 'medium' }}
          />
        </Box>
      </Card>
    </Box>
  ) : (
    <LoadingSpinner />
  )
}

export default EditQuestion
