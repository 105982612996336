import { Icon } from '@iconify/react'
import { Box, ResponsiveContext, Text } from 'grommet'
import React, { useRef, useState } from 'react'

import { useOutsideDetect } from '../util'

type DropdownItem = Object & {
  _id: string
  name: string
}

interface DropdownProps {
  data: DropdownItem[]
  placeholder?: string
  selected?: string
  onChange?: (id: string) => void
}

const Dropdown = ({ placeholder = 'No data', data, selected, onChange }: DropdownProps) => {
  const outsideRef = useRef()
  const [isOpen, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(selected || null)

  useOutsideDetect(outsideRef, () => isOpen && toggleDropdown())

  const toggleDropdown = () => setOpen(!isOpen)

  const handleItemClick = id => {
    selectedItem === id ? setSelectedItem(null) : setSelectedItem(id)
    toggleDropdown()
    onChange && onChange(id)
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box ref={outsideRef} background="accent-2" round="small" margin={{ vertical: 'small' }}>
          <Box
            onClick={toggleDropdown}
            pad={size === 'small' ? 'medium' : 'small'}
            align="center"
            justify="between"
            direction="row"
            focusIndicator={false}
            tabIndex={0}
            aria-label="Dropdown to select a question set"
            aria-haspopup="menu"
          >
            <Text size={size === 'small' ? 'small' : 'small'}>
              {selectedItem != null
                ? data.find(item => item._id === selectedItem).name
                : placeholder}
            </Text>
            <Icon
              icon="ic:sharp-keyboard-arrow-right"
              fontSize={24}
              style={{
                transition: 'all 0.2s ease-in-out',
                transform: !isOpen ? 'rotate(0deg)' : 'rotate(90deg)',
              }}
            />
          </Box>
          <Box
            pad="small"
            justify="center"
            direction="column"
            style={{
              display: isOpen ? 'flex' : 'none',
              zIndex: 1,
            }}
            aria-hidden={!isOpen}
          >
            {data.map((item, idx) => (
              <Box
                onClick={() => handleItemClick(item._id)}
                key={idx}
                direction="row"
                hoverIndicator={{ background: 'accent-4' }}
                pad={{ vertical: size === 'small' ? 'xsmall' : 'xsmall', left: 'small' }}
                margin={{ vertical: 'xxsmall' }}
                background={{ color: selectedItem === item._id ? 'accent-3' : 'accent-2' }}
                round="xsmall"
                align="center"
                wrap={false}
                a11yTitle={item.name}
                aria-selected={selectedItem === item._id}
                tabIndex={0}
              >
                <Icon
                  icon="ic:sharp-play-arrow"
                  fontSize={20}
                  style={{ opacity: selectedItem === item._id ? 1 : 0 }}
                />
                <Text size={size === 'small' ? 'xsmall' : 'small'} margin={{ left: 'small' }}>
                  {item.name}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Dropdown
