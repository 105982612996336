import { Box, Text } from 'grommet'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { Button, Input } from '../../../../ui'
import { useAnswerQuestion } from '../../../../util/socket'
import type { TextQuestionType } from '../../state/questionState'

interface TextQuestionProps {
  data: TextQuestionType
}

const TextQuestion = ({ data }: TextQuestionProps) => {
  const [guess, setGuess] = useState(null)

  const sendAnswer = useAnswerQuestion()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setGuess(e.target.value)

  const answerQuestion = () => {
    if (!guess) return toast.info('You have to enter an answer')
    if (/[^a-zA-Z]/.test(guess)) return toast.info('Only text is allowed')

    sendAnswer(guess)
  }

  return (
    <>
      <Box pad={'medium'} round="xsmall" background={'#E6C8A9'}>
        <Text>{data.questionText}</Text>
      </Box>
      <Input margin={{ vertical: 'small' }} label={'Type answer here'} onChange={handleChange} />
      <Button label="Answer Question" onClick={answerQuestion} />
    </>
  )
}

export default TextQuestion
