import { Icon } from '@iconify/react'
import { Box, Nav, Menu, ResponsiveContext, Text, ButtonExtendedProps } from 'grommet'
import { compact } from 'lodash'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useLoggedIn, useLogout, useReset, useUser } from '../state'

import type { Entry } from './Navigation'

interface NavigationItemsProps {
  entiries?: Entry[]
}

const NavigationItems = ({ entiries }: NavigationItemsProps) => {
  const user = useUser()
  const isLoggedIn = useLoggedIn()
  const location = useLocation()
  const navigate = useNavigate()

  const logout = useLogout()
  const reset = useReset()

  const onLogout = async () => {
    await logout()
    reset()
  }

  const isActiveRoute = (name: string) => {
    if (location.pathname === '/' && name.includes('Home')) return true
    return location.pathname.includes(name.toLowerCase())
  }

  const mobileItems = [
    ...compact(
      entiries.map(entry =>
        !entry.locked || (entry.locked && isLoggedIn)
          ? {
              label: (
                <Box
                  color="black"
                  pad="small"
                  fill="horizontal"
                  background={isActiveRoute(entry.name) ? 'dark-2' : 'white'}
                  hoverIndicator="dark-2"
                  round="xsmall"
                  onClick={() => navigate(entry.path)}
                >
                  <Text size="small">
                    {entry.name === 'Dashboard' ? user?.username : entry.name}
                  </Text>
                </Box>
              ),
            }
          : null,
      ),
    ),
    {
      label: (
        <Box
          color="black"
          pad="small"
          fill="horizontal"
          round="xsmall"
          onClick={isLoggedIn ? onLogout : () => navigate('login')}
          hoverIndicator="dark-2"
        >
          <Text size="small"> {isLoggedIn ? 'Logout' : 'Login'}</Text>
        </Box>
      ),
    },
  ]

  return (
    <ResponsiveContext.Consumer>
      {size =>
        size === 'small' ? (
          <Box justify="end" role="presentation">
            <Menu
              a11yTitle="Navigation Menu"
              color={'brand'}
              dropProps={{
                align: { top: 'bottom', right: 'right' },
                margin: { top: 'medium' },
                width: 'large',
              }}
              icon={<Icon icon="ic:round-menu" fontSize={42} color="white" />}
              items={mobileItems as ButtonExtendedProps[]}
              aria-haspopup
              plain
            />
          </Box>
        ) : (
          <Nav direction="row" gap="small" align="center" role="menubar">
            {entiries?.map((entry, index) =>
              !entry.locked || (entry.locked && isLoggedIn) ? (
                <Box role="menuitem" key={index} onClick={() => navigate(entry.path)}>
                  <Text role="" size="small" color={isActiveRoute(entry.name) ? 'dark-2' : 'white'}>
                    {entry.name === 'Dashboard' ? user?.username : entry.name}
                  </Text>
                </Box>
              ) : null,
            )}
            <Box role="menuitem" onClick={isLoggedIn ? onLogout : () => navigate('login')}>
              <Text color={isActiveRoute('login') ? 'dark-2' : 'white'} size="small">
                {isLoggedIn ? 'Logout' : 'Login'}
              </Text>
            </Box>
          </Nav>
        )
      }
    </ResponsiveContext.Consumer>
  )
}

export default NavigationItems
