import { Box } from 'grommet'
import { BackgroundType, DirectionType, MarginType, PadType } from 'grommet/utils'
import React from 'react'

interface CardProps {
  background?: BackgroundType
  direction?: DirectionType
  margin?: MarginType
  pad?: PadType
  wrap?: boolean
  flex?:
    | boolean
    | 'grow'
    | 'shrink'
    | {
        grow?: number
        shrink?: number
      }
  shadow?: boolean
  ariaDescribedby?: string
}

const Card = ({
  direction,
  background,
  margin,
  pad,
  ariaDescribedby,
  children,
  flex,
  wrap = true,
  shadow = true,
}: React.PropsWithChildren<CardProps>) => {
  return (
    <Box
      as="section"
      direction={direction || 'column'}
      border={{ color: 'black', size: 'medium' }}
      round="xsmall"
      pad={pad || 'medium'}
      margin={margin || 'medium'}
      background={background || 'light-1'}
      wrap={wrap}
      fill="horizontal"
      elevation={shadow ? 'xlarge' : 'none'}
      width="xlarge"
      aria-describedby={ariaDescribedby}
      flex={flex}
    >
      {children}
    </Box>
  )
}

export default Card
